import { useEffect, useState } from "react";

// material-ui

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,

  InputLabel,
 
  Stack,

  TextField,
  Tooltip,

} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
import { DeleteFilled } from "@ant-design/icons";
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import AlertCompanyReplyDelete from "./AlertCompanySuggestedDelete";

import {
  addCompanySuggested,
  editCompanySuggested,
} from "store/actions/main/companyList/suggestedCompany";
import { SuggestedCompany } from "model/companyList/suggestedCompany";

// constant
const getInitialValues = (customer: SuggestedCompany | null) => {
  const newCustomer = {
    ar_name: "",
    en_name: "",
  };
  if (customer) {
    // Extract country code and mobile number parts from mobile number if present

    return {
      ...newCustomer,
      ...customer,
    };
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCompanySuggestedAdd = ({
  customer,
  closeModal,
  fetchData,
}: {
  fetchData: any;
  id: number;
  customer: SuggestedCompany | null | any;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const CustomerSchema = Yup.object().shape({
    en_name: Yup.string().required("English Name is required"),
    ar_name: Yup.string().required("Arabic Name is required"),
  });
  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editCompanySuggested({
              id: customer.id || 0,
              data: values,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "companyList/editCompanySuggested/fulfilled") {
              openSnackbar({
                open: true,
                message: "Company Suggested updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addCompanySuggested(values)).then((action) => {
            fetchData();
            if (action.type === "companyList/addCompanySuggested/fulfilled") {
              openSnackbar({
                open: true,
                message: "Company Suggested added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Company Suggested" : "New Company Suggested"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-en_name">
                          English Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-en_name"
                          placeholder="Enter English Name"
                          {...getFieldProps("en_name")}
                          error={Boolean(touched.en_name && errors.en_name)}
                          helperText={touched.en_name && errors.en_name}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-ar_name">
                          Arabic Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-ar_name"
                          placeholder="Enter Arabic Name"
                          {...getFieldProps("ar_name")}
                          error={Boolean(touched.ar_name && errors.ar_name)}
                          helperText={touched.ar_name && errors.ar_name}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCompanyReplyDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.user_name}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormCompanySuggestedAdd;
