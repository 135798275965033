import { useAppDispatch } from "hooks";
import {  useEffect, useState } from "react";
import { useLocation } from "react-router";

// material-ui
import {

  Box,


  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
 
  FormControl,

  FormControlLabel,

  Select,
  Switch,
  Button
} from "@mui/material";

import { Formik } from "formik";

import MainCard from "components/MainCard";

// types


import {
  ReadResume,
  UnReadResume,
  activeCv,
  addNewBasicInfo,
  deactivateCv,
  editOneBasicInfo,

} from "store/actions/main/cv/basicinfo/basicInfo";

import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";
import ResumeSendNote from "../basicInfo/ResumeSendNote";
import ResumeSendEmail from "../basicInfo/resumeSendEmail";

const BasicInfoSection = ({
  setbasicResumeId,
  basicInfo,
  setBasicInfo,
  resumeId,
  basicResumeId,
  fetchGetOne,
  userId,
}: {
  fetchGetOne: any;
  setLoading: any;
  loading: any;
  setBasicInfo: any;
  basicInfo: any;
  userId: any;
  setbasicResumeId: any;
  resumeId: any;
  basicResumeId: any;
}) => {
  const maxDate = new Date();

  const location = useLocation();

  const pathname = location.pathname;
  const [openModal, setOpenModal] = useState<boolean>(false);


  const id = pathname.substring(pathname.lastIndexOf("/") + 1);

  maxDate.setFullYear(maxDate.getFullYear() - 18);
    // @ts-ignore
  const [activeSection, setActiveSection] = useState(0); // State variable for active section index

  useEffect(() => {
    const navButtons = document.querySelectorAll(".nav-button");
    navButtons.forEach((button) => {
      button.classList.remove("active");
    });
    const activeButton = document.querySelector(`#nav-button-${activeSection}`);
    if (activeButton) {
      activeButton.classList.add("active");
    }
  }, [activeSection]);
  const handleClose = () => {
    setOpenModal(!openModal);
  };
  const handleSendNote = () => {
   
    setOpenModal(true)
  };
 
  const dispatch = useAppDispatch();
  // Step 3: State to store child cities

  // This will give you the value "2" from the URL

  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value, type, checked } = event.target;

    // Handle different input types
    const newValue =
      type === "checkbox"
        ? checked
        : name === "read"
          ? value === "true"
          : value;

    // Update profileData state with the new value
    setBasicInfo((prevProfileData) => ({
      ...prevProfileData,
      [name]: newValue,
    }));
  };
  const handleToggleAccountStatus = (status: any) => {
    // Update the status locally in the table data

    dispatch(
      status === "inactive"
        ? activeCv({ cvId: resumeId || 0 })
        : deactivateCv({ cvId: resumeId || 0 }),
    )
      .then(() => {
        fetchGetOne();
      })
      .catch((error) => {
        console.error("Error toggling account status:", error);
      });
  };
  const handleToggleAccountRead = (read: any) => {
    // Update the status locally in the table data

    dispatch(
      read === false
        ? ReadResume({ cvId: resumeId || 0 })
        : UnReadResume({ cvId: resumeId || 0 }),
    )
      .then(() => {})
      .catch((error) => {
        console.error("Error toggling account status:", error);
      });
  };
  console.log(basicInfo);
  return (
    <>
      <Stack direction={"row"} pb={2}>
        <MainCard
          content={false}
          title="Basic Information"
          sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }}
        >
          {/* <div>Resume iD  <span>{resumeId}</span></div>
          <div>Print Resume <Button >Print</Button></div>
          <div>Send Note <Button onClick={(e: any) => {
                    e.stopPropagation();
                    handleClose();
                    
                  }}  >Send</Button></div>
          <div>Send Email <Button >Send</Button></div> */}
          <Formik
            initialValues={{
              user_id: userId,
              lang: basicInfo?.lang || "",
              status: basicInfo?.status || "",
              read: basicInfo?.status || "",

              submit: null,
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (isNaN(resumeId)) {
                dispatch(
                  addNewBasicInfo({
                    user_id: basicInfo.user_id,
                    //  read: basicInfo.read,
                    lang: basicInfo.lang,
                    status: basicInfo.status,
                  }),
                ).then((action) => {
                  const data = action.payload["result:  "];
                  setbasicResumeId(data.id);
                  if (action.type === "resumes/addNewBasicInfo/fulfilled") {
                    openSnackbar({
                      open: true,
                      message: "profile added successfully",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      variant: "alert",
                      alert: {
                        color: "success",
                      },
                    } as SnackbarProps);
                    setSubmitting(false);
                  }
                });
              } else {
                dispatch(
                  editOneBasicInfo({
                    cvId: resumeId,
                    data: {
                      user_id: id,
                      read: basicInfo.read,
                      lang: basicInfo.lang,
                      status: basicInfo.status,
                    },
                  }),
                ).then((action) => {
                  if (action.type === "resumes/editOneBasicInfo/fulfilled") {
                    openSnackbar({
                      open: true,
                      message: "resume updated successfully",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      variant: "alert",
                      alert: {
                        color: "success",
                      },
                    } as SnackbarProps);
                    setSubmitting(false);
                  }
                });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => (
              <>
                <form noValidate onSubmit={handleSubmit}>
                  <Box>
                    <Box sx={{ p: 2.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="lang">Language</InputLabel>
                            <FormControl fullWidth>
                              <Select
                                name="lang"
                                value={basicInfo?.lang}
                                onChange={(e:any)=>handleChange(e)}
                                onBlur={handleBlur}
                                readOnly
                              >
                                <MenuItem value="EN">EN</MenuItem>
                                <MenuItem value="AR">AR</MenuItem>
                              </Select>
                              {basicInfo.lang === "" &&
                                errors.lang &&
                                touched.lang && (
                                  <FormHelperText style={{ color: "red" }}>{/*  @ts-ignore */}
                                    {errors?.lang} 
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="lang">Status</InputLabel>

                            <FormControlLabel
                              control={
                                <Switch
                                  key={resumeId}
                                  checked={basicInfo.status}
                                  onChange={() => {
                                    // Ensure that account_status exists and has a valid value
                                    const currentStatus = basicInfo.status;
                                    const newStatus =
                                      typeof currentStatus === "boolean"
                                        ? currentStatus === true
                                          ? "active"
                                          : "inactive"
                                        : "";
                                    handleToggleAccountStatus(newStatus);
                                  }}
                                />
                              }
                              label={
                                basicInfo.status === true
                                  ? "Active"
                                  : "Inactive"
                              }
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="read">Read</InputLabel>

                            <FormControlLabel
                              control={
                                <Switch
                                  key={resumeId}
                                  checked={basicInfo.read}
                                  onChange={() => {
                                    // Ensure that account_status exists and has a valid value
                                    const currentStatus = basicInfo.read;

                                    handleToggleAccountRead(currentStatus);
                                  }}
                                />
                              }
                              label={
                                basicInfo.read === true ? "Read" : "Unread"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12}>
                        <Button
                            onClick={ handleSendNote}
                            type="submit"
                            variant="contained"
                          >
                            Send Note
                          </Button> 
                 
                        </Grid>
                      </Grid>
                    </Box>
                    </Box>
                   
                </form>
              </>
            )}
          </Formik>
        </MainCard>
      </Stack>
      <ResumeSendNote id={resumeId} open={openModal} handleClose={handleClose}  />
      <ResumeSendEmail id={resumeId} open={openModal} handleClose={handleClose} />
    </>
  );
};
export default BasicInfoSection;
