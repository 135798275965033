import { createAsyncThunk } from "@reduxjs/toolkit";
import companyUrl from "../../../utils/url/company";
//axios
import { AXIOS } from "../../../api/axios";

//error message
import responseError from "../../../utils/api/error-handling";


//get AllUser
export const getAllCompanies = createAsyncThunk(
  "company/getCompany",
  async ({ pageNumber,name }: { pageNumber: number,name?:string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `https://test.syriajob.com/public/api/admin/companies?page=1`,{ params: { user_name: name }}
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//GET ONE USER

export const getOneCompany = createAsyncThunk(
  "company/getOneCompany",
  async ({ companyId }: { companyId: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${companyUrl.GET_ONE_COMPANY}${companyId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editCompany = createAsyncThunk(
  "company/editCompany",
  async ({ companyId, data }: { companyId: number; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${companyUrl.EDIT_COMPANY}${companyId}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addNewCompany = createAsyncThunk(
  "company/addCompany",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(companyUrl.GET_COMPANY, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteCompany = createAsyncThunk(
  "company/deleteCompany",
  async ({ companyId }: { companyId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        `${companyUrl.GET_ONE_COMPANY}${companyId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const activeAccount = createAsyncThunk(
  "company/activeAccount",
  async ({ companyId }: { companyId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${companyUrl.ACIVATE_ACCOUNT}${companyId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deactivateAccount = createAsyncThunk(
  "company/deactivateAccount",
  async ({ companyId }: { companyId: number }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${companyUrl.DEACTIVATE_ACCOUNT}${companyId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCompanies = createAsyncThunk(
  "company/MultiDeletCompanies",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(companyUrl.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const FilterCompanies = createAsyncThunk(
  "company/FilterCompanies",
  async (
    {
      account_status,
      account_language,
      created_at_start,
      created_at_end,
    }: {
      account_status: string;
      account_language?: string;
      created_at_start?: Date | null;
      created_at_end?: Date | null;
    },
    thunkAPI,
  ) => {
    try {
      const data = {
        account_status,
        account_language,
        created_at_start,
        created_at_end,
      };
      const response = await AXIOS.post(companyUrl.FILTER_USER, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const SearchCompanies = createAsyncThunk(
  "company/SearchCompanies",
  async ({ data }: { data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${companyUrl.SEARCH_COMPANIES}${data}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const toggleAccountStatus =
  (index: any, status: any) => (dispatch: any) => {
    if (status === "active") {
      dispatch(deactivateAccount({ companyId: index }));
    } else {
      dispatch(activeAccount({ companyId: index }));
    }
  };

export const totalAllCompanies = createAsyncThunk(
  "company/totalCompanies",
  async (_, thunkAPI) => {
    try {
      const response = await AXIOS.get(companyUrl.TOTAL_COMPANIES);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
