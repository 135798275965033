import { createAsyncThunk } from "@reduxjs/toolkit";
//axios
import { AXIOS } from "../../../api/axios";

//error message
import responseError from "../../../utils/api/error-handling";

//get AllUser
export const getAllTranslationsCategory = createAsyncThunk(
  "role/getAllTranslationsCategory",
  async (_, thunkAPI) => {
    try {
      const response = await AXIOS.get("https://test.syriajob.com/public/api/admin/translationCategory");
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);