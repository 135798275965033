import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import basicInfoUrl from "../../../../../utils/url/cv/basicInfo/basicInfo";
//axios

//get AllBasicInfo
export const getAllBasicInfo = createAsyncThunk(
  "resumes/getAllBasicInfo",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/admin/resumes?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const getAllTitles = createAsyncThunk(
  "resumes/getAllTitles",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/titles?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getOnebasicInfo = createAsyncThunk(
  "resumes/getOnebasicInfo",
  async ({ cvId }: { cvId: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${basicInfoUrl.BASIC_INFO_ONE}${cvId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
// get resume statistics
export const getResumeStatistics = createAsyncThunk(
  "resumes/getOnebasicInfo",
  async ({ cvId }: { cvId: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${basicInfoUrl.RESUME_STATISTICS}${cvId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editOneBasicInfo = createAsyncThunk(
  "resumes/editOneBasicInfo",
  async ({ cvId, data }: { cvId: string; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${basicInfoUrl.BASIC_INFO_ONE}${cvId}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewBasicInfo = createAsyncThunk(
  "resumes/addNewBasicInfo",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(basicInfoUrl.BASIC_INFO, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteBasicInfo = createAsyncThunk(
  "resumes/deleteBasicInfo",
  async ({ cvId }: { cvId: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${basicInfoUrl.BASIC_INFO_ONE}${cvId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const activeCv = createAsyncThunk(
  "resumes/activeCv",
  async ({ cvId }: { cvId: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${basicInfoUrl.ACTIVATE_CV}${cvId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deactivateCv = createAsyncThunk(
  "resumes/deactivateCv",
  async ({ cvId }: { cvId: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${basicInfoUrl.DEACTIVATE_CV}${cvId}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const totalALLCV = createAsyncThunk(
  "resumes/totalALLCV",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get("https://test.syriajob.com/public/api/admin/resumes/total");
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const getAllActiveResume = createAsyncThunk(
  "resumes/getAllActiveResume",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(basicInfoUrl.GET_ALL_RESUME_ACTIVE);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const getAllInActiveResume = createAsyncThunk(
  "resumes/getAllInActiveResume",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        basicInfoUrl.GET_ALL_RESUME_INACTIVE,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const ReadResume = createAsyncThunk(
  "resumes/ReadResume",
  async ({ cvId }: { cvId: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.postForm(
        `${basicInfoUrl.READ_RESUME}${cvId}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const UnReadResume = createAsyncThunk(
  "resumes/UnReadResume",
  async ({ cvId }: { cvId: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${basicInfoUrl.UNREAD_RESUME}${cvId}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
