import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _, { values } from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets

// types
import { SnackbarProps } from "types/snackbar";
import { CustomerList } from "types/customer";
import { useAppDispatch } from "hooks";

import {
  addNewNote,
  editNote,
} from "store/actions/main/adminNote";
import { CircularProgress } from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { AdminNote } from "model/basicInfo";
import TextArea from "antd/es/input/TextArea";




// constant
const getInitialValues = (customer: AdminNote | null) => {
  const newCustomer = {
    title: '',
    lang: '',
    message: '',
    resume_lang: '',
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};
// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCustomerAdd = ({
  customer,
  id,
  loading,
  fetchData,
  setLoading,
  closeModal,
}: {
  loading: any;
  id: number;
  setLoading: any;
  fetchData: any;
  customer: CustomerList | any;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    title:  Yup.string().required("Title is required"),
    lang:  Yup.string().required("Language is required"),
    message:  Yup.string().required("Message is required"),
    resume_lang:  Yup.string().required("Resume language is required")
  });


  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editNote({
              noteId: customer.id || 0,
              data: {
                title: values.title,
                lang:  values.lang,
                message:  values.message,
                resume_lang:  values.resume_lang
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "note/editNote/fulfilled") {
              openSnackbar({
                open: true,
                message: "Note type edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewNote({
              title: values.title,
              lang:  values.lang,
              message:  values.message,
              resume_lang:  values.resume_lang
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "note/addNote/fulfilled") {
              openSnackbar({
                open: true,
                message: "Note type added successfully!",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });
  
  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? "Edit note type details" : "Add new note type"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
              <Grid item xs={12} sm={12} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-title">
                          Title  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the tilte of the note type'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-title"
                          placeholder="Title"
                          {...getFieldProps("title")}
                          error={Boolean(
                            touched.title && errors.title,
                          )}
                          helperText={
                            touched.title && errors.title
                          }
                        />
                      </Stack>
                </Grid>
                <Grid item xs={12} sm={5} mb={1}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="customer-type">Language <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                              <Tooltip title={'Set the language of the note type'}>
                                <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                              </Tooltip>
                            </span>
                          </InputLabel>
                          <Select
                            id="customer-type"
                            displayEmpty
                            {...getFieldProps("lang")}
                            onChange={(event: SelectChangeEvent<boolean>) => {
                              const selectedValue = event.target.value;
                              setFieldValue("lang", selectedValue);
                            }}>
                            <MenuItem value={"AR"}>Arabic</MenuItem>
                            <MenuItem value={"EN"}>English</MenuItem>
                          </Select>
                        </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} sm={5} mb={1}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="customer-type"> Resume Language <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                              <Tooltip title={'Set the resume language of the note type'}>
                                <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                              </Tooltip>
                            </span>
                          </InputLabel>
                          <Select
                            id="customer-type"
                            displayEmpty
                            {...getFieldProps("resume_lang")}
                            onChange={(event: SelectChangeEvent<boolean>) => {
                              const selectedValue = event.target.value;
                              setFieldValue("resume_lang", selectedValue);
                            }}>
                            <MenuItem value={"AR"}>Arabic</MenuItem>
                            <MenuItem value={"EN"}>English</MenuItem>
                          </Select>
                        </Stack>
                </Grid>
                <Grid item xs={12} sm={11} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-note">
                      Message  <span style={{color:'red'}}>*</span>
                        <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the message of the note type'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                    </InputLabel>
                    <TextArea
                      id="customer-note"
                      placeholder="Note"
                      {...getFieldProps("message")}
                    />
                  </Stack>
                  {touched.message && errors.message && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{ pl: 1.75 }}>
                      {errors.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormCustomerAdd;
