import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS } from "api";
import responseError from "utils/api/error-handling";
import jobTypeURL from "../../../../utils/url/basicInfo/jobType";

//get AllUser
export const getAllJobType = createAsyncThunk(
  "basicInfo/getAllJobType",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `https://test.syriajob.com/public/api/guest/job_type?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER
export const getAllJobTypeSelect = createAsyncThunk(
  "basicInfo/getAllJobType",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `https://test.syriajob.com/public/api/job_seeker_resume/get_job_type`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const getOneJobType = createAsyncThunk(
  "basicInfo/getOneJobType",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${jobTypeURL.GET_ONE_JOB_TYPE}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editJobType = createAsyncThunk(
  "basicInfo/editJobType",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${jobTypeURL.EDIT_JOB__TYPE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addJobType = createAsyncThunk(
  "basicInfo/addJobType",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(jobTypeURL.ADD_JOB___TYPE, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deletJobType = createAsyncThunk(
  "basicInfo/deletJobType",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        `${jobTypeURL.DELETE_JOB__TYPE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletJobType = createAsyncThunk(
  "basicInfo/MultiDeletJobType",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(jobTypeURL.MULTI_DELET_JOB___TYPE, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
