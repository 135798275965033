import { useEffect, useState ,useRef } from "react";

// material-ui

import 'react-quill/dist/quill.snow.css';
import { Formik } from "formik";
import {
  Box,
  Button,
FormControl,
  DialogTitle,
  Divider,
  Grid,

  InputLabel,

  MenuItem,

  Select,

  Stack,

  TextField,
  DialogContent,
  DialogActions,
  CircularProgress,

} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

// third-party
import _ from "lodash";
import * as Yup from "yup";


// project imports

import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets


// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";




import MainCard from "components/MainCard";

import { addNewTranslation, editTranslation, getOneTranslations } from "store/actions/main/translations";
import { getAllTranslationsCategory } from "store/actions/main/translationCategory";

// constant

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormPageManagmentTranslation = ({

  id,
  closeModal,

}: {

  id: any,
  closeModal: () => void;


}) => {

  const pathname = window.location.pathname;

  // Split the pathname into segments using "/"
  const segments = pathname.split("/");
 
  // Extract the resume ID (second-to-last segment) and user ID (last segment)

  const vacancyId = segments[segments?.length - 1];
  console.log(vacancyId,"vacnacy id");


  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
const [customer, setcustomer] = useState<any>();
const [translationCategories, settranslationCategories] = useState([]);
const [selectedCategoryTranslatoion, setselectedCategoryTranslatoion] = useState<any>();
// @ts-ignore
const [isMilitaryServiceDisabled, setIsMilitaryServiceDisabled] =
useState(false);
const [categoriesDetails, setcategoriesDetails] = useState<any>();
console.log(customer,"customer");
  useEffect(() => {
    setLoading(false);
  }, []);
 

 

  useEffect(() => {
    console.log(vacancyId,"vacancy id");
    if(vacancyId!=="0"){
      console.log("im if");
      
    dispatch(getOneTranslations({id:id})).then((action)=>{
console.log(action.payload,"data vacancy");
 setvacancyData(action.payload[0])
      //   const  {data}  = action.payload;
      //   console.log(data,"vacancy data");
      //   setvacancyData(data)
       
      // setcustomer(data)  
    })}
  }, [dispatch]);
useEffect(() => {
    dispatch(getAllTranslationsCategory()).then((action)=>{
        const data = action.payload;
        console.log(data,"translation category");
        settranslationCategories(data)
    })
}, [dispatch]);
  const [vacancyData, setvacancyData] = useState({
    id:0,
    en_name:"",
    ar_name:"",
    key:""
    
 

  });
  console.log(vacancyData,"vcancy data");
  
  const validationSchema = Yup.object().shape({
    ar_name:
      vacancyData?.ar_name === ""
        && Yup.string().required("Arabic name is required"),
        key: vacancyData?.key=== ""
        &&  Yup.string().required("Key is required"),
       
        
        
       
      
    // Add more fields as needed
    // Add more fields as needed

    // Add more fields as needed
  });

  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value, type, checked } = event.target;

    // Handle different input types
    const newValue = type === "checkbox" ? checked : value;

    console.log(newValue,"new value");
    
    console.log(name,"name");
    
    // Update profileData state with the new value
    setvacancyData((prevProfileData) => ({
      ...prevProfileData,
      [name]: newValue,
    }));
console.log(vacancyData,"updates");

    // Toggle military service field based on selected gender
    if (name === "gender" && value === "FEMALE") {
      setIsMilitaryServiceDisabled(true);
    } else if (name === "gender" && value === "MALE") {
      setIsMilitaryServiceDisabled(false);
    }
  };
  



  
 

  
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
  <Formik
                initialValues={{
                 
                  ar_name: vacancyData?.ar_name,
                  en_name:vacancyData?.en_name,
                  key: vacancyData?.key,
                 

                }}
                validationSchema={validationSchema} // Pass the validation schema to Formik
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values,"values");
                  
                  // Check if account_photo is an image
                 
                    // Prepare FormData object

                    const formData = new FormData();
                      console.log(vacancyData,"vacancy data");
                                         
                    formData.append("en_name", vacancyData?.en_name);

                    formData.append("ar_name", vacancyData?.ar_name);
                    formData.append("key", vacancyData?.key);
                    formData.append("translation_category_id", selectedCategoryTranslatoion);
                    
                    // @ts-ignore
                   
                    

                   // Assuming code is an object with only one key-value pair

                    // Concatenate country code and phone number

                  
                   
                    if (id===undefined) {
                      console.log(formData,"values form data");
                      
                      dispatch(addNewTranslation(formData)).then(
                        (action) => {
                          if (
                            action.type ===
                            "jobSeeker/addNewJobSeekerProfile/fulfilled"
                          ) {
                            openSnackbar({
                              open: true,
                              message: "profile added successfully",
                              anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                              },
                              variant: "alert",
                              alert: {
                                color: "success",
                              },
                            } as SnackbarProps);
                            setSubmitting(false);
                            
              closeModal();
                          }
                        },
                      );
                    } else {
                      dispatch(
                        editTranslation({
                          id: id,
                          data: formData,
                        }),
                      ).then((action) => {
                        if (
                          action.type ===
                          "jobSeeker/editJobSeekerProfile/fulfilled"
                        ) {
                          openSnackbar({
                            open: true,
                            message: "profile Updated successfully",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            variant: "alert",
                            alert: {
                              color: "success",
                            },
                          } as SnackbarProps);
                          setSubmitting(false);
                          closeModal();
                        }
                      });
                    }
                    closeModal();
                    // Send FormData with Axios
                    // Set file error message
                   // Your form submission logic here
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values
                }) => (
                  <form className="form-vacancy-container" noValidate onSubmit={handleSubmit}>
      
        <LocalizationProvider>
          
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px',}} >
              {id ? "Edit Translation" : "New Translation"}
             
            </DialogTitle>
            <Divider />
         
    <DialogContent sx={{ p: 2.5 }}>
          
            <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="en_name">
                        Key
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                          value={vacancyData?.key}
                          name="key" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                       
                         
                          placeholder="key"
                         
                          
                        />
                        
                      </Stack>
                    </Grid>
                  <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="en_name">
                        En Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                          value={vacancyData?.en_name}
                          name="en_name" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 10 }}
                         
                          placeholder="en_name"
                          
                          
                        />
                        
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                      <InputLabel htmlFor="customr-package_validity">
                      ar_name
                        </InputLabel>
                        <TextField
                          fullWidth
                          
                          value={vacancyData?.ar_name}
                          name="ar_name" // Add the name attribute
                          onChange={handleChange}
                          onBlur={handleBlur}
                         
                          placeholder="ar_name"
                          
                          
                        />
                        </Stack></Grid>


                          <Grid item xs={12} sm={6}>
                            <Stack spacing={1}>
                             
                                <InputLabel htmlFor="lang">
                                Translation Category
                                </InputLabel>
                                
                            
                            
                                  <FormControl fullWidth>
                                    <Select
                                      
                                      value={selectedCategoryTranslatoion}
                                      onChange={(e:any)=>{handleChange(e);setselectedCategoryTranslatoion(e.target.value);console.log(e.target.value,"selected")}}
                                      onBlur={handleBlur}
                                      
                                      name="status"
                                    >
                                      {translationCategories.map((category)=>(
                                          <MenuItem value={category.id}>
                                         {category.name}
                                          </MenuItem>)
                                      )}
                                    
                                    
                                      
                                    </Select>
                                  </FormControl>
                              
                            </Stack>
                          </Grid>
                          

                        </Grid></Grid>
        
                        </DialogContent>
           
<Divider />
            <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="center" alignItems="center">
               <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                                  <Grid item md={2}/>
                                  <Grid item md={4}>
                                    <Button fullWidth type="submit" variant="contained">
                                      {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                                    </Button>
                                  </Grid>
                                  <Grid item md={4}>
                                    <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                                      Cancel
                                    </Button>
                                  </Grid>
                                  <Grid item md={2}/>
                                </Stack>
            {/* <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button
                            
                            type="submit"
                            variant="contained"
                          >
                            Save
                          </Button> 
                          </Stack> */}
                          </Grid>
                          </DialogActions>
            <Divider />
       
        
     
        </LocalizationProvider>
    </form>
                )}
      </Formik>
    </>
  );
};

export default FormPageManagmentTranslation;
