import { useEffect, useState } from "react";

// material-ui

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";
// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AlertBestPlaceDelete from "./AlertBestPlaceDelete";
import { bestPlace } from "model/ads";
import { addBestPlace, editBestPlace } from "store/actions/main/ads/bestPlace";
import { FormControl } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { CircularProgress } from "@mui/material";

// constant
const getInitialValues = (customer: bestPlace | null) => {
  const newCustomer = {
    user_id: "",
    vacancy_order_id: "",
    category_id: "",
    status: "",
    end_date: "",
    start_date: "",
  };

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormBestPlaceAdd = ({
  customer,
  id,
  loading,
  Packages,
  catName,
  userName,
  closeModal,
  setLoading,
  fetchData,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  Packages: any;
  catName: any;
  userName: any;
  id: number;
  customer: bestPlace | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    vacancy_order_id: Yup.number().required("Vacancy is required"),
    category_id: Yup.number().required("Category is required"),
    end_date: Yup.number().required("End date is required"),
    start_date: Yup.number().required("Start date is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  useEffect(() => {
    setLoading(false);

    // Format the start and end dates if they are provided as Dayjs objects
    if (customer) {
      const formattedStartDate = customer.start_date
        ? dayjs(customer.start_date).format("YYYY-MM-DD")
        : null;
      const formattedEndDate = customer.end_date
        ? dayjs(customer.end_date).format("YYYY-MM-DD")
        : null;
      setStartDate(formattedStartDate);
      // @ts-ignore
      setEndDate(formattedEndDate);
    } else {
      // If customer data doesn't exist, set default values for start and end dates
      setStartDate(null);
      setEndDate(null);
    }
  }, [dispatch]);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setStartDate(formattedDate); // Update the state directly with the formatted date
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    // @ts-ignore
    setEndDate(formattedDate); // Update the state directly with the formatted date
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          console.log(values); // Dispatch editCategories action if customer exists
          await dispatch(
            editBestPlace({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                category_id: values.category_id,
                status: values.status,
                vacancy_order_id: values.vacancy_order_id,
                start_date: startDate,
                end_date: endDate,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "ads/editBestPlace/fulfilled") {
              openSnackbar({
                open: true,
                message: "Best Place update successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addBestPlace({
              user_id: values.user_id,
              category_id: values.category_id,
              status: values.status,
              vacancy_order_id: values.vacancy_order_id,
              start_date: startDate,
              end_date: endDate,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "ads/addBestPlace/fulfilled") {
              openSnackbar({
                open: true,
                message: "Best Place added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;


  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', color: 'rgb(22, 119, 255)', marginBottom: '10px' }}>
              {customer ? "Edit best place details" : "New best place"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                <Grid item xs={12} sm={11} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-user_id">
                      Company Name <span style={{ color: 'red' }}>*</span>
                      <span style={{ color: 'grey', marginLeft: '5px' }}>
                        <Tooltip title={'Set the company name'}>
                          <HelpOutlineOutlinedIcon style={{ width: '15px', height: '15px', cursor: 'pointer' }} />
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <Select
                      id="customer-user_id"
                      displayEmpty
                      {...getFieldProps("user_id")}
                      onChange={(event: SelectChangeEvent<string>) => {
                        const selectedId = event.target.value;
                        const selectedUser = userName.find(
                          (user) => user.id === selectedId,
                        );
                        const selectedUserName = selectedUser
                          ? selectedUser.user_name
                          : "";
                        setFieldValue("user_id", selectedId);
                        setFieldValue("user_name", selectedUserName); // Set user name value to another field
                      }}
                      value={getFieldProps("user_id").value || ""}
                    >
                      {userName &&
                        userName.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.user_name}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.user_id && errors.user_id && (
                      <FormHelperText error>
                        {errors.user_id}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-vacancy_order_id">
                      Vacancy Order  <span style={{ color: 'red' }}>*</span>
                      <span style={{ color: 'grey', marginLeft: '5px' }}>
                        <Tooltip title={'Set the Vacancy Order'}>
                          <HelpOutlineOutlinedIcon style={{ width: '15px', height: '15px', cursor: 'pointer' }} />
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <Select
                      id="customer-vacancy_order_id"
                      displayEmpty
                      {...getFieldProps("vacancy_order_id")}
                      onChange={(event: SelectChangeEvent<string>) => {
                        const selectedId = event.target.value;
                        const selectedPackage = Packages.find(
                          (pkg: any) => pkg.id === selectedId,
                        );
                        const selectedType = selectedPackage
                          ? selectedPackage.id
                          : "";
                        setFieldValue("vacancy_order_id", selectedId);
                        setFieldValue("vacancy_order_id", selectedType); // Set type value to another field
                      }}
                      value={getFieldProps("vacancy_order_id").value || ""}
                    >
                      {Packages &&
                        Packages.map((pkg: any) => (
                          <MenuItem key={pkg.id} value={pkg.id}>
                            {pkg.id}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.vacancy_order_id &&
                      errors.vacancy_order_id && (
                        <FormHelperText error>
                          {errors.vacancy_order_id}
                        </FormHelperText>
                      )}
                  </Stack>
                </Grid>
                <Grid md={1} />
                <Grid item xs={12} sm={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-category_id">
                      Category Name <span style={{ color: 'red' }}>*</span>
                      <span style={{ color: 'grey', marginLeft: '5px' }}>
                        <Tooltip title={'Set the category name'}>
                          <HelpOutlineOutlinedIcon style={{ width: '15px', height: '15px', cursor: 'pointer' }} />
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <Select
                      id="customer-category_id"
                      displayEmpty
                      {...getFieldProps("category_id")}
                      onChange={(event: SelectChangeEvent<string>) => {
                        const selectedId = event.target.value;

                        setFieldValue("category_id", selectedId);
                        // setFieldValue("category_id", selectedUserName); // Set user name value to another field
                      }}
                      value={getFieldProps("category_id").value || ""}
                    >
                      {catName &&
                        catName.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {touched.category_id && errors.category_id && (
                      <FormHelperText error>
                        {errors.category_id}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-start_date">
                      Start Date  <span style={{ color: 'red' }}>*</span>
                      <span style={{ color: 'grey', marginLeft: '5px' }}>
                        <Tooltip title={'Set the start date'}>
                          <HelpOutlineOutlinedIcon style={{ width: '15px', height: '15px', cursor: 'pointer' }} />
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={startDate ? dayjs(startDate) : null}
                        onChange={(date) => handleDateChange(date)}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid md={1} />
                <Grid item xs={12} sm={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-end_date">
                      End Date <span style={{ color: 'red' }}>*</span>
                      <span style={{ color: 'grey', marginLeft: '5px' }}>
                        <Tooltip title={'Set the end date'}>
                          <HelpOutlineOutlinedIcon style={{ width: '15px', height: '15px', cursor: 'pointer' }} />
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={endDate ? dayjs(endDate) : null}
                        onChange={(date) => handleEndDateChange(date)}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-is_active">
                      Status
                      <span style={{ color: 'grey', marginLeft: '5px' }}>
                        <Tooltip title={'Set the best place status (Default inactive)'}>
                          <HelpOutlineOutlinedIcon style={{ width: '15px', height: '15px', cursor: 'pointer' }} />
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            // @ts-ignore
                            checked={formik.values.status}
                            {...getFieldProps("status")}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setFieldValue("status", checked);
                            }}
                          />
                        }
                        label={formik.values.status ? "Active" : "Inactive"}
                      />
                    </FormControl>
                    {touched.status && errors.status && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{ pl: 1.75 }}
                      >
                        {errors.status}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                  <Grid item md={2} />
                  <Grid item md={4}>
                    <Button fullWidth type="submit" variant="contained">
                      {isSubmitting ? <CircularProgress size="1.5rem" style={{ color: 'white' }} /> : 'Submit'}
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item md={2} />
                </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertBestPlaceDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.id}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormBestPlaceAdd;
