import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS } from "api";
import responseError from "utils/api/error-handling";
import degreeURL from "../../../../utils/url/basicInfo/degreeType";

//get AllUser
export const getAllDegree = createAsyncThunk(
  "basicInfo/getAllDegree",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `https://test.syriajob.com/public/api/admin/degree_types?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER
export const getAllDegreeSelect = createAsyncThunk(
  "basicInfo/getAllDegree",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `https://test.syriajob.com/public/api/job_seeker_resume/get_degree_type`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getOneDegree = createAsyncThunk(
  "basicInfo/getOneDegree",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${degreeURL.GET_ONE_DEGREE__TYPE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editDegree = createAsyncThunk(
  "basicInfo/editDegree",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${degreeURL.EDIT_DEGREE__TYPE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addDegree = createAsyncThunk(
  "basicInfo/addDegree",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(degreeURL.ADD_DEGREE__TYPE, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteDegree = createAsyncThunk(
  "basicInfo/deleteDegree",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        `${degreeURL.DELETE_DEGREE__TYPE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletDegree = createAsyncThunk(
  "basicInfo/MultiDeletDegree",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(degreeURL.MULTI_DELET_DEGREE__TYPE, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
