// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Tooltip,
  FormControl,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { BasicInfo } from "model/basicInfo";

import { addJobType, editJobType } from "store/actions/main/basicInfo/jobTypes";
import { CircularProgress } from "@mui/material";
import { FormControlLabel } from "@mui/material";

// constant
const getInitialValues = (customer: BasicInfo | null) => {
  if (customer) {
    return {
      name: customer.name || "",
      order: customer.order || "",
      status: customer.status || false,
      name_trans: customer.name_trans|| ""
    };
  } else {
    return {
      name: "",
      name_trans: "",
      order: "",
      status: false,
    };
  }
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormJobTypesAdd = ({
  customer,
  closeModal,
  fetchData,
  loading,
}: {
  loading: any;
  setLoading: any;
  fetchData: any;
  id: number;
  customer: BasicInfo | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();

  const CustomerSchema = Yup.object().shape({
    name: Yup.string().required("English Name is required"),
    name_trans: Yup.string().required("Arabic Name is required"),
    order: Yup.number().required("Order is required"),
  });

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          await dispatch(
            editJobType({
              id: customer.id || 0,
              data: values,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "basicInfo/editJobType/fulfilled") {
              openSnackbar({
                open: true,
                message: "Job Types edited successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addJobType(values)).then((action) => {
            fetchData();
            if (action.type === "basicInfo/addJobType/fulfilled") {
              openSnackbar({
                open: true,
                message: "Job Types added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });
  console.log('formik.values',formik.values);

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? "Edit job type details" : "Add new job type"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name">English name <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={'Enter english name for the job type'}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="customer-name"
                      placeholder="Enter english name"
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                </Grid>
                <Grid item md={1}/>
                <Grid item xs={12} md={5} mb={1}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-name">Arabic name <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={'Enter arabic name for the job type'}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                        fullWidth
                        id="customer-name"
                        name="name_trans"
                        {...getFieldProps("name_trans")}
                        placeholder="Enter Arabic Name"
                        error={Boolean(touched.name_trans && errors.name_trans)}
                        helperText={touched.name_trans && errors.name_trans}
                      />
                  </Stack>
                </Grid>
                <Grid md={1}/>
                <Grid item xs={12} sm={5}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-order">Order <span style={{color:'red'}}>*</span>
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={'Type the number of orders in this job type'}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type="number"
                      id="customer-order"
                      placeholder="Enter Order"
                      {...getFieldProps("order")}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                    />
                  </Stack>
                </Grid>
                <Grid md={1}/>
                <Grid item xs={12} md={5}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="customer-is_active">
                      Status
                      <span style={{color:'grey', marginLeft:'5px'}}>
                        <Tooltip title={'Set the job type status (Default inactive)'}>
                          <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                        </Tooltip>
                      </span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={formik.values.status}
                            {...getFieldProps("status")}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setFieldValue("status", checked);
                            }}
                          />
                        }
                        label={formik.values.status ? "Active" : "Inactive"}
                      />
                    </FormControl>
                    {touched.status && errors.status && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{ pl: 1.75 }}
                      >
                        {errors.status}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormJobTypesAdd;
