import { useEffect, useState } from "react";

// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,

  InputLabel,
  MenuItem,

  Select,
  SelectChangeEvent,
  Stack,

  TextField,
  Tooltip,
  Autocomplete,
 
} from "@mui/material";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";
import { openSnackbar } from "api/snackbar";
// assets
import { DeleteFilled } from "@ant-design/icons";
// types
import { SnackbarProps } from "types/snackbar";
import { useAppDispatch } from "hooks";
import { getAllBasicInfo } from "store/actions/main/cv/basicinfo/basicInfo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { CompanyComment } from "model/companyList/companyComment";
import AlertCompanyCommentDelete from "./AlertCompanyCommentsDelete";
import {
  addCompanyComments,
  editCompanComments,
} from "store/actions/main/companyList/commentsCompany";
import { getAllCompanies } from "store/actions/main/companies";
import { getAllActionsComment } from "store/actions/main/basicInfo/commentAction";

// constant
const getInitialValues = (customer: CompanyComment | null) => {
  const newCustomer = {
    from_user_id: "",
    to_user_id: "",
    company_id: "",
    action: "",
    date: "",
    text: "",
  };
  if (customer) {
    // Extract country code and mobile number parts from mobile number if present

    return {
      ...newCustomer,
      ...customer,
    };
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormCompanyCommentAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
}: {
  fetchData: any;
  id: number;
  customer: CompanyComment | null;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const CustomerSchema = Yup.object().shape({
    from_user_id: Yup.string().required("From User Name is required"),
    to_user_id: Yup.string().required("To User Name is required"),
    company_id: Yup.string().required("Company Name is required"),
    action: Yup.string().required("Action is required"),
    date: Yup.string().required("Date is required"),
    text: Yup.string().required("Text is required"),
  });
  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };
  const [selectedJobSeeker, setSelectedJobSeeker] = useState([]);
  const [selectedJobSeeker2, setSelectedJobSeeker2] = useState([]);
  const [selectedComanyName, setSelectedComapnyName] = useState([]);
  const [selectedAction, setSelectedAction] = useState([]);
  const fetchDataPackages = () => {
    dispatch(getAllBasicInfo({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setSelectedJobSeeker(data);
        setSelectedJobSeeker2(data);
        // Set default selected country to the first one in the list
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchReply = () => {
    dispatch(getAllCompanies({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setSelectedComapnyName(data);
        // Set default selected country to the first one in the list
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchaction = () => {
    dispatch(getAllActionsComment({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setSelectedAction(data);
        // Set default selected country to the first one in the list
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchDataPackages();
    fetchReply();
    fetchaction();
  }, [dispatch]);
  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editCompanComments({
              id: customer.id || 0,
              data: values,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "companyList/editCompanComments/fulfilled") {
              openSnackbar({
                open: true,
                message: "Company Comment updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(addCompanyComments(values)).then((action) => {
            fetchData();
            if (action.type === "companyList/addCompanyComments/fulfilled") {
              openSnackbar({
                open: true,
                message: "Company Comment added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              setSubmitting(false);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const { value} = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );
  console.log(value);
  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {customer ? "Edit Company Comment" : "New Company Comment"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-from_user_id">
                          From User
                        </InputLabel>
                        <Autocomplete
                          id="customer-from_user_id"
                          options={selectedJobSeeker}
                          getOptionLabel={(option) => option.user_name || ""}
                          onChange={(event, newValue) => {
                            const selectedId = newValue ? newValue.id : "";
                            const selectedVacancyName = newValue
                              ? newValue.user_name
                              : "";
                            setFieldValue("from_user_id", selectedId);
                            setFieldValue("user_name", selectedVacancyName); // Set user name value to another field
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...getFieldProps("from_user_id")}
                            />
                          )}
                          value={
                            selectedJobSeeker.find(
                              (user) =>
                                user.id === getFieldProps("from_user_id").value,
                            ) || null
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-to_user_id">
                          To User
                        </InputLabel>
                        <Autocomplete
                          id="customer-to_user_id"
                          options={selectedJobSeeker2}
                          getOptionLabel={(option) => option.user_name || ""}
                          onChange={(event, newValue) => {
                            const selectedId = newValue ? newValue.id : "";
                            const selectedVacancyName = newValue
                              ? newValue.user_name
                              : "";
                            setFieldValue("to_user_id", selectedId);
                            setFieldValue("user_name", selectedVacancyName); // Set user name value to another field
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...getFieldProps("to_user_id")}
                            />
                          )}
                          value={
                            selectedJobSeeker2.find(
                              (user) =>
                                user.id === getFieldProps("to_user_id").value,
                            ) || null
                          }
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-company_id">
                          Company Name
                        </InputLabel>
                        <Autocomplete
                          id="customer-company_id"
                          options={selectedComanyName}
                          getOptionLabel={(option) => option.user_name || ""}
                          onChange={(event, newValue) => {
                            const selectedId = newValue ? newValue.id : "";
                            const selectedVacancyName = newValue
                              ? newValue.user_name
                              : "";
                            setFieldValue("company_id", selectedId);
                            setFieldValue("user_name", selectedVacancyName); // Set user name value to another field
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...getFieldProps("company_id")}
                            />
                          )}
                          value={
                            selectedComanyName.find(
                              (user) =>
                                user.id === getFieldProps("company_id").value,
                            ) || null
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-action">
                          Action
                        </InputLabel>
                        <Select
                          id="customer-action"
                          displayEmpty
                          {...getFieldProps("action")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = selectedAction.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedVacanvyName = selectedUser
                              ? selectedUser?.title
                              : "";
                            setFieldValue("action", selectedId);
                            setFieldValue("en_value", selectedVacanvyName); // Set user name value to another field
                          }}
                          value={getFieldProps("action").value || ""}
                        >
                          {selectedAction &&
                            selectedAction.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.en_value}
                              </MenuItem>
                            ))}
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-date">Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={dayjs(getFieldProps("date").value) || null} // Ensure the value is a Dayjs object
                            onChange={(date) => {
                              // Ensure the date is formatted as Y-m-d before setting the field value
                              const formattedDate = date
                                ? dayjs(date).format("YYYY-MM-DD")
                                : null;
                              setFieldValue("date", formattedDate);
                            }}
                            // @ts-ignore
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                              />
                            )}
                            renderDay={(day, _value, _DayComponentProps) => (
                              <span>{dayjs(day).format("YYYY-M-D")}</span>
                            )}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-action">Text</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-text"
                          placeholder="Enter Text"
                          {...getFieldProps("text")}
                          error={Boolean(touched.text && errors.text)}
                          helperText={touched.text && errors.text}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {customer && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {customer ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {customer && (
        <AlertCompanyCommentDelete
          fetchData={fetchData}
          id={customer.id!}
          title={customer.text}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default FormCompanyCommentAdd;
