import { createAsyncThunk } from "@reduxjs/toolkit";
import resumesURL from "../../../../utils/url/packages/resumes";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
//get AllUser
export const getAllResumes = createAsyncThunk(
  "packages/getAllResumes",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${'https://test.syriajob.com/public/api/admin/resume_packages'}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneResume = createAsyncThunk(
  "packages/getOneResume",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${resumesURL.GET_ONE_RESUMES_PACKAGES}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editOneResume = createAsyncThunk(
  "packages/editOneResume",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${resumesURL.EDIT_RESUMES_PACKAGES}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addNewResumePacakges = createAsyncThunk(
  "packages/addNewResumePacakges",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        resumesURL.ADD_RESUMES_PACKAGES,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteResume = createAsyncThunk(
  "packages/deleteResume",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${resumesURL.DELETE_RESUMES_PACKAGES}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletdeleteResumes = createAsyncThunk(
  "packages/MultiDeletdeleteResumes",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(resumesURL.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
