// material-ui
import {
    Button,
    Dialog,
    DialogContent,
    Stack,
    Typography,
  } from "@mui/material";
  
  // project import
  import Avatar from "components/@extended/Avatar";
  import { PopupTransition } from "components/@extended/Transitions";
  
  //import { deleteCustomer } from 'api/customer';
  import { openSnackbar } from "api/snackbar";
  
  // assets
  import { DeleteFilled } from "@ant-design/icons";
  
  // types
  import { SnackbarProps } from "types/snackbar";
  import { useAppDispatch } from "hooks";
import { deleteTranslations } from "store/actions/main/translations";
  
  interface Props {
    id: number;
    title: string;
    open?: boolean;
    fetchData?: any;
    handleClose?: () => void;
  }
  
  // ==============================|| CUSTOMER - DELETE ||============================== //
  
  export default function AlertTranslationsDelete({
    id,
    title,
    open,
    handleClose,
    fetchData,
  }: Props) {
    const dispatch = useAppDispatch();
    const handleDelete = () => {
      dispatch(deleteTranslations({ id: id })).then((action) => {
        fetchData();
        if (action.type === "roles/deleteRoles/fulfilled") {
          openSnackbar({
            open: true,
            message: "Roles deleted successfully",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            variant: "alert",
            alert: {
              color: "success",
            },
          } as SnackbarProps);
          handleClose();
        }
      });
    };
    console.log(id);
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        keepMounted
        TransitionComponent={PopupTransition}
        maxWidth="xs"
        aria-labelledby="column-delete-title"
        aria-describedby="column-delete-description"
      >
        <DialogContent sx={{ mt: 2, my: 1 }}>
          <Stack alignItems="center" spacing={3.5}>
            <Avatar
              color="error"
              sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
            >
              <DeleteFilled />
            </Avatar>
            <Stack spacing={2}>
              <Typography variant="h4" align="center">
                Are you sure you want to delete?
              </Typography>
              <Typography align="center">
                By deleting
                <Typography variant="subtitle1" component="span">
                  {" "}
                  "{title}"{" "}
                </Typography>
                all task assigned to that role will also be deleted.
              </Typography>
            </Stack>
  
            <Stack direction="row" spacing={2} sx={{ width: 1 }}>
              <Button
                fullWidth
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={handleDelete}
                autoFocus
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }
  