const GET_ALL_JOB_SEEKERS = "jobSeekers";

const ADD_JOB_SEEKER = "jobSeekers";

const EDIT_JOB_SEEKER = "jobSeekers/";

const GET_ONE_JOB_SEEKER = "jobSeekers/";

const DELETE_JOB_SEEKER = "jobSeekers/";

export default {
  GET_ALL_JOB_SEEKERS,
  ADD_JOB_SEEKER,

  EDIT_JOB_SEEKER,

  GET_ONE_JOB_SEEKER,
  DELETE_JOB_SEEKER,
};
