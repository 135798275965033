import { createAsyncThunk } from "@reduxjs/toolkit";
import  noteUrl from "../../../utils/url/adminNote";
//USERAXIOS
import { USERAXIOS } from "../../../api";

//urls
//import aboutURL from '../../../utils/url/about';

//error message
import responseError from "../../../utils/api/error-handling";

//get AllUser
export const getAllAdminNote = createAsyncThunk(
  "note/getAllNotes",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/admin/admin_note_types?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//GET ONE USER

export const getOneNote = createAsyncThunk(
  "note/getOneNote",
  async ({ noteId }: { noteId: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(`${noteUrl.ADMIN_NOTE}${noteId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editNote = createAsyncThunk(
  "note/editNote",
  async ({ noteId, data }: { noteId: number; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${noteUrl.ADMIN_NOTE}${noteId}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const addNewNote = createAsyncThunk(
  "note/addNote",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post("https://test.syriajob.com/public/api/admin/admin_note_types", data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const addNewNoteRecord = createAsyncThunk(
  "note/addNoteRecord",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post("https://test.syriajob.com/public/api/admin/admin_notes", data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteNote = createAsyncThunk(
  "note/deleteNote",
  async ({ noteId }: { noteId: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${noteUrl.ADMIN_NOTE}${noteId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletnotes = createAsyncThunk(
  "note/MultiDeletNotes",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(noteUrl.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);