import { useMemo, useState } from "react";

// material-ui
import {  Modal} from "@mui/material";

// project-imports

import MainCard from "components/MainCard";
import SimpleBar from "components/third-party/SimpleBar";


// types

import { Title } from "model/basicInfo";
import FormTitleAdd from "./FormTitleAdd";

interface Props {
  open: boolean;
  modalToggler: (state: boolean) => void;
  customer?: Title | null;
  id: number;
  fetchData: any;
}

// ==============================|| CUSTOMER ADD / EDIT ||============================== //

const TitleModal = ({ open, fetchData, modalToggler, customer, id }: Props) => {
  const closeModal = () => modalToggler(false);

  const [loading, setLoading] = useState(false);
  const customerForm = useMemo(
    () => (
      <FormTitleAdd
        id={id}
        loading={loading}
        setLoading={setLoading}
        fetchData={fetchData}
        customer={customer || null}
        closeModal={closeModal}
      />
    ),

    // eslint-disable-next-line
    [customer],
  );

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-customer-add-label"
          aria-describedby="modal-customer-add-description"
          sx={{
            "& .MuiPaper-root:focus": {
              outline: "none",
            },
          }}
        >
          <MainCard
            sx={{
              width: `calc(100% - 48px)`,
              minWidth: 340,
              maxWidth: 600,
              height: "auto",
              maxHeight: "calc(100vh - 48px)",
            }}
            modal
            content={false}
          >
            <SimpleBar
              sx={{
                maxHeight: `calc(100vh - 48px)`,
                "& .simplebar-content": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {customerForm}
            </SimpleBar>
          </MainCard>
        </Modal>
      )}
    </>
  );
};

export default TitleModal;
