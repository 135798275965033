import { useMemo, useState } from "react";

// material-ui
import { Box, Modal, Stack } from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import SimpleBar from "components/third-party/SimpleBar";

// types
import { UserList } from "types/customer";

import FormCompanyAdd from "./FormCompanyAdd";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

interface Props {
  open: boolean;
  modalToggler: (state: boolean) => void;
  customer?: UserList | null;
  id: number;
  fetchData: any;
}

// ==============================|| CUSTOMER ADD / EDIT ||============================== //

const CompanyModal = ({
  open,
  modalToggler,
  customer,
  id,
  fetchData,
}: Props) => {
  const closeModal = () => modalToggler(false);
   // @ts-ignore
  const [loading, setLoading] = useState(false);
  const customerForm = useMemo(
    () =>
      !loading && (
        <FormCompanyAdd
          id={id}
          fetchData={fetchData}
          customer={customer || null}
          closeModal={closeModal}
        />
      ),
    // eslint-disable-next-line
    [customer, loading],
  );

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-customer-add-label"
          aria-describedby="modal-customer-add-description"
          sx={{
            "& .MuiPaper-root:focus": {
              outline: "none",
            },
          }}
        >
          <MainCard
            sx={{
              width: `calc(100% - 48px)`,
              minWidth: 340,
              maxWidth: 880,
              height: "auto",
              maxHeight: "calc(100vh - 48px)",
            }}
            modal
            content={false}
          >
            <SimpleBar
              sx={{
                maxHeight: `calc(100vh - 48px)`,
                "& .simplebar-content": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {loading ? (
                <Box sx={{ p: 5 }}>
                  <Stack direction="row" justifyContent="center">
                    <CircularWithPath />
                  </Stack>
                </Box>
              ) : (
                customerForm
              )}
            </SimpleBar>
          </MainCard>
        </Modal>
      )}
    </>
  );
};

export default CompanyModal;
