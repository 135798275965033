// material-ui
import {
    Button,
    Dialog,
    DialogContent,
    Stack,
    Grid,
    InputLabel,
    
    FormControl,
    MenuItem,
    DialogTitle,
    Divider,
    DialogActions
  } from "@mui/material";
  import { SnackbarProps } from "types/snackbar";
  // project import


  
  //import { deleteCustomer } from 'api/customer';

  
  // assets
 
  
  // types
 
  import { useAppDispatch } from "hooks";

import { Select } from "@mui/material";
import {  addNewNoteRecord, getAllAdminNote } from "store/actions/main/adminNote";
import { useEffect, useState } from "react";
import { openSnackbar } from "api/snackbar";
  
  interface Props {
    id: string;
    title?: string;
    open?: boolean;
    handleClose?: () => void;
    fetchData?: any;
  }
  
  // ==============================|| CUSTOMER - DELETE ||============================== //
  
  export default function ResumeSendNote({
    id,
    title,
    open,
    handleClose,
    fetchData,
  }: Props) {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const [notesOptions, setnotesOptions] = useState<any>([]);
    const [selectedOption, setselectedOption] = useState<any>();
    const handleEditNote = () => {
        // @ts-ignore
      dispatch(addNewNoteRecord({ resume_id: id,admin_resume_note_type_id:selectedOption })).then((action) => {
        console.log(action,"action");
        
          if (
            action.type ===
            "note/addNoteRecord/fulfilled"
          ) {
            openSnackbar({
              open: true,
              message: "Note added successfully",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "alert",
              alert: {
                color: "success",
              },
            } as SnackbarProps);
      
          }
        
      });
    };


useEffect(() => {
    dispatch(getAllAdminNote({pageNumber:1})).then((action)=>{
        console.log(action.payload,"action payload");
        const { data } = action.payload;
        console.log(data,"data");
     
        data.map((note:any)=>{
          notesOptions.push({value:note.id,label:note.
            message
            })
        })
        console.log(notesOptions,"notes option");
        
        }) 
}, [dispatch]);
  
    return (
      <Dialog
        open={open}
        onClose={handleClose}
      
        
      >
      
          
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px' }}>Send Note</DialogTitle>
        <DialogContent style={{    width: "488px"}} >
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                    
                        <InputLabel htmlFor="customer-email">Note</InputLabel>
                        <FormControl style={{marginBottom:"20px"}} fullWidth>
                              <Select
                              value={selectedOption}
                                name="note"
                               
                              onChange={(e)=>setselectedOption(e.target.value)}
                               
                              >
                                {notesOptions.map((option)=>(
                                  <MenuItem key={option.id} value={option.value}>{option.label}</MenuItem>
                                ))}
                                
                               
                              </Select>
                              
                            </FormControl>
                     
                    </Grid>
                    </Grid>
                    <Divider/>
          
        
        </DialogContent>
        <Divider/>
              <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                    <Button
                fullWidth
                type="submit"
                variant="contained"
                onClick={handleEditNote}
                autoFocus
              >
               Send
              </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
      </Dialog>
    );
  }
  